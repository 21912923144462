<template>
  <div id="plateChart">
    <div class="pages hidden" id="plateChartPages">
      <div class="page_title"><span class="text hidden" id="plateChartText"></span></div>

      <div :class="{ none: !isPlateAriseChartVisible }">
        <div class="plate_summary_row">
          <span class="p_secname">{{ plateName }}板块</span>
          <span :class="`p_wave ${isPlateRatePositive ? 'rise' : 'fall'}`"
            >{{ isPlateRatePositive ? "+" : "-" }} {{ plateAbsRate }} %</span
          >
        </div>
        <div class="plate_chart" ref="refPlateBarChartHolder">
          <bar-chart-horizen ref="refHorizenBarChart" id="plateBarChart" customClass="bar_chart" />
          <svg width="300" height="300" class="svg_gradient">
            <defs>
              <linearGradient id="plateBarNegative" x1="0%" y1="0%" x2="100%" y2="0">
                <stop offset="0%" stop-color="#11ba06" />
                <stop offset="100%" stop-color="#0b8603" />
              </linearGradient>
              <linearGradient id="plateBarPositive" x1="0%" y1="0%" x2="100%" y2="0">
                <stop offset="0%" stop-color="#BF0101" />
                <stop offset="100%" stop-color="#FB3232" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>

      <div :class="{ none: !isPlateTrendChartVisible }" ref="refPlateTrendHolder">
        <chart-legend ref="refPlateTrendLegend" id="plateTrendLegend" class="common_legend" />
        <plate-line-chart ref="refPlateTrendChart" id="plateTrendChart" class="trend_chart" />
      </div>
    </div>
  </div>
</template>
<script>
//特别注意：使用jquery的时候为了避免重名带来的干扰，选择器开头必须是plateChart
import $ from "jquery";
import anime from "animejs/lib/anime.es.js";
import { anyElement } from "@/components/anyElement.js";
import { sleep, setRem } from "@/utils/utils.js";
import barChartHorizen from "@/components/barChartHorizen/index_v1.0.vue";
import plateLineChart from "@/components/plateLineChart/index.vue";
import chartLegend from "@/components/chartLegend/index.vue";

export default {
  data: function () {
    return {
      titleText: null, //动画句柄
      plateSeccode: "",
      plateName: "",
      plateAbsRate: "",
      isPlateRatePositive: true,
      isPlateAriseChartVisible: false,
      isPlateTrendChartVisible: false, //板块
    };
  },
  components: {
    barChartHorizen,
    plateLineChart,
    chartLegend,
  },
  methods: {
    preview(commandData) {
      setRem(1080);
      this.show(commandData)
    },
    async hide() {
      return new Promise(async (resolve, reject) => {
        if (this.titleText) {
          this.titleText.runOut();
        }
        if (this.plateSummary) {
          this.plateSummary.runOut();
        }
        await sleep(200);
        this.isPlateAriseChartVisible = false;
        this.isPlateTrendChartVisible = false;
        $("#plateChartPages").addClass("hidden");
        resolve();
      });
    },
    async show(commandData) {
      $("#plateChartPages").removeClass("hidden");
      $("#plateChartText").html(commandData.secname + "板块" + commandData.chartType);
      this.$nextTick(async () => {
        $("#plateChartText").removeClass("hidden");
        this.titleText = anyElement({
          targets: document.querySelector("#plateChartText"),
          isSplitTxt: true,
          duration: 600,
          delay: 30,
          easing: "easeOutExpo",
          inEffect: "rightInBig",
          outEffect: "leftOutBig",
        });
        await sleep(300);
        this.titleText.runIn();

        this.handleChart(commandData);
      });
    },
    handleChart(commandData) {
      if (commandData.chartType == "涨幅top10" || commandData.chartType == "跌幅top10") {
        $(".plate_summary_row").removeClass("hidden");
        this.plateSeccode = commandData.data.plateQuote.sDtSecCode;
        this.plateName = commandData.data.plateQuote.sSecName;
        this.plateAbsRate = commandData.data.plateQuote.absRate;
        this.isPlateRatePositive = commandData.data.plateQuote.isRatePositive;
        this.isPlateAriseChartVisible = true;

        this.$nextTick(async () => {
          this.plateSummary = anyElement({
            targets: document.querySelectorAll(".plate_summary_row span"),
            isSplitTxt: false,
            duration: 900,
            delay: 100,
            easing: "easeOutExpo",
            inEffect: "runIn",
            outEffect: "leftOutBig",
          });
          this.plateSummary.runIn();

          this.renderBarChart(commandData.data.plateStocks);
        });
      } else if (commandData.chartType == "板块潜力") {
        this.isPlateTrendChartVisible = true;
        this.$nextTick(() => {
          this.renderPlateTrendChart(commandData.data);
        });
      }
    },
    //主图K线图
    renderBarChart(data) {
      let width = this.$refs.refPlateBarChartHolder.clientWidth;
      this.$refs.refHorizenBarChart.BarChart(data, {
        x: (d) => d.absRate * (d.isRatePositive ? 1 : -1),
        y: (d) => d.sSecName,
        isNegativeSameDirection: true,
        numberSuffix: "%",
        width: width,
        height: width * 0.7,
        marginLeft: 155,
        marginRight: width * 0.25,
        duration: 900,
        delay: 120,
        yPadding: 0.4,
        ease: "easeExp",
        numberPosition: "outside",
      });
    },
    //板块走势潜力
    renderPlateTrendChart(data) {
      //展示legend
      this.$refs.refPlateTrendLegend.createLegend({
        legend: data.legend,
        legendType: "line",
        colors: ["#FF689B", "#3C88D4"],
      });

      let width = this.$refs.refPlateTrendHolder.clientWidth;
      this.$refs.refPlateTrendChart.LineChart(data.data, {
        label: (d) => d.label,
        width: width,
        height: width * 0.74,
        marginLeft: 100,
        marginRight: 30,
        marginTop: 20,
        marginBottom: 60,
        duration: 2000,
        delay: 0,
        xTickNumber: 3,
        yTickNumber: 6,
        ease: "easeQuadOut",
        numberSuffix: "%",
      });
    },
  },

  async mounted() {
    window.hide = this.hide;
    window.show = this.preview;
    this.$bus.$on("chartExit", () => {
      this.hide();
    });
  },
};
</script>
<style lang="less" scoped>
@import "index.less";
</style>
