<template>
  <div :id="id" :class="customClass"></div>
</template>

<script>
import * as d3 from "d3";
import $ from "jquery";
import { sleep, randomNum, demicalLength } from "@/utils/utils.js";
export default {
  props: ["id", "customClass"],
  data() {
    return {
      svg: null,
      svgLineTotalLength: 0,
      svgLineTotalArr: [],
    };
  },
  methods: {
    async playOut() {
      for (let i = 0; i < this.svgLineTotalArr.length; i++) {
        this.svg
          .selectAll(`.line${i + 1}`)
          .attr("stroke-dashoffset", 0)
          .transition()
          .duration(600)
          .ease(d3.easeCubicIn)
          .attr("stroke-dashoffset", -this.svgLineTotalArr[i]);
      }

      this.svg.selectAll(`.axis_x .domain`).transition().duration(300).style("opacity", "0");
      this.svg.selectAll(`.axis_x .tick_line`).transition().duration(600).style("opacity", "0");
      this.svg
        .selectAll(`.axis_x .text`)
        .transition()
        .duration(300)
        .delay((d, i) => i * 40)
        .ease(d3.easeCircleOut)
        .style("opacity", "0");
      this.svg.selectAll(`.axis_y .domain`).transition().duration(300).style("opacity", "0");
      this.svg
        .selectAll(`.axis_y .tick_line, .axis_y .tick_long_line`)
        .transition()
        .duration(600)
        .style("opacity", "0");
      this.svg
        .selectAll(`.axis_y .text`)
        .transition()
        .duration(300)
        .delay((d, i) => i * 40)
        .ease(d3.easeCircleOut)
        .style("opacity", "0");
    },
    async init() {
      await this.getSettings();
    },
    getSettings() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          resolve();
        });
      });
    },
    //核心库
    async LineChart(
      data,
      {
        label = (d, i) => d, // given d in data, returns the (ordinal) x-value
        line1 = (d) => d, // given d in data, returns the (quantitative) y-value
        line2 = (d) => d, // given d in data, returns the (quantitative) y-value
        curveName = "curveLinear",
        marginTop = 40, // the top margin, in pixels
        marginRight = 50, // the right margin, in pixels
        marginBottom = 30, // the bottom margin, in pixels
        marginLeft = 40, // the left margin, in pixels
        width = 640, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        xDomain, // an array of (ordinal) x-values
        xRange = [marginLeft, width - marginRight], // [left, right]
        yDomain, // [ymin, ymax]
        yRange = [height - marginBottom, marginTop], // [bottom, top]
        yFormat = ",f", // a format specifier string for the y-axis
        duration = 400, //动画持续时长
        delay = 40, //元素之间间隔时长
        ease = "easeQuadOut", //元素之间间隔时长
        xTickNumber = 5,
        yTickNumber = 5,
        lineColors = [],
        lineWidth = [],
        numberSuffix = "", //每根线提示数据的单位
      } = {}
    ) {
      const curve = d3[curveName];
      const X = d3.map(data, label);
      let lineData = [], //折线数据 格式[[],[]] 表示有两条线
        lineCount = 0; //多少跟线
      for (let i = 0; i < data.length; i++) {
        let item = data[i];
        lineCount = 0;
        for (const key in item) {
          if (key.search("value") != -1) {
            lineCount++;
          }
        }
      }
      for (let i = 0; i < lineCount; i++) {
        let arr = [];
        for (let j = 0; j < data.length; j++) {
          arr[j] = data[j]["value" + (i + 1)];
        }
        lineData[i] = arr;
      }
      const YArray = [];
      for (let i = 0; i < lineData.length; i++) {
        let item = lineData[i];
        YArray.push(d3.min(item));
        YArray.push(d3.max(item));
      }
      const I = d3.range(X.length);

      if (xDomain === undefined) xDomain = X;
      if (yDomain === undefined) yDomain = [d3.min(YArray), d3.max(YArray)];

      console.log(yDomain);

      const xScale = d3.scalePoint(xDomain, xRange);
      const yScale = d3.scaleLinear(yDomain, yRange);

      //算出每几个标一个tick
      let tickValuesArr = [];
      if (xTickNumber == 2) {
        tickValuesArr.push(xDomain[0]);
        tickValuesArr.push(xDomain[xDomain.length - 1]);
      } else if (xTickNumber == 3) {
        tickValuesArr.push(xDomain[0]);
        tickValuesArr.push(xDomain[Math.floor(xDomain.length / 2)]);
        tickValuesArr.push(xDomain[xDomain.length - 1]);
      } else if (xTickNumber > 3) {
        const modeNum = Math.floor(data.length / xTickNumber);
        tickValuesArr = xScale.domain().filter((d, i) => i % modeNum == 0);
      }

      const xAxis = d3.axisBottom(xScale).tickSize(10).tickSizeOuter(0).tickValues(tickValuesArr);
      const yAxis = d3.axisLeft(yScale).ticks(yTickNumber, yFormat).tickSize(10).tickSizeOuter(0);

      const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
      this.svg = svg;

      //y轴坐标轴
      const axisY = svg
        .append("g")
        .attr("class", "axis_y")
        .attr("transform", `translate(${marginLeft},0)`)
        .call(yAxis)
        .call((g) => {
          g.select(".domain").attr("opacity", 0);
          g.selectAll(".tick line").attr("class", "tick_line").attr("opacity", 0);
          g.selectAll(".tick line")
            .clone()
            .attr("x2", width - marginLeft - marginRight)
            .attr("class", "tick_long_line");
          g.selectAll(".tick text").attr("class", "text").attr("opacity", 0);
        });
      axisY.selectAll(".domain").transition().duration(600).attr("opacity", 1);
      axisY.selectAll(".tick_line").transition().duration(600).attr("opacity", 1);
      axisY.selectAll(".tick_long_line").transition().duration(600).attr("opacity", 1);
      axisY
        .selectAll(".text")
        .transition()
        .delay((d, i) => i * delay)
        .ease(d3.easeCircleOut)
        .duration(600)
        .attr("opacity", 1);

      //x轴坐标轴
      const axisX = svg
        .append("g")
        .attr("class", "axis_x")
        .attr("transform", `translate(0,${height - marginBottom + 1})`)
        .call(xAxis)
        .call((g) => {
          g.select(".domain").attr("opacity", 0);
          g.selectAll(".tick line").attr("class", "tick_line").attr("opacity", 0);
          g.selectAll(".tick text").attr("class", "text").attr("dy", "1.2em").attr("opacity", 0);
        });

      axisX.selectAll(".domain").transition().duration(600).attr("opacity", 1);
      axisX.selectAll(".tick_line").transition().duration(600).attr("opacity", 1);
      axisX
        .selectAll(".text")
        .transition()
        .delay((d, i) => i * delay)
        .ease(d3.easeCircleOut)
        .duration(600)
        .attr("opacity", 1);

      //画线
      for (let i = 0; i < lineCount; i++) {
        const color = !lineColors[i] ? "#000" : lineColors[i];
        const width = !lineWidth[i] ? 1 : lineWidth[i];
        const Y = lineData[i];
        const pathLine = d3
          .line()
          .curve(curve)
          .x((i) => xScale(X[i]))
          .y((i) => yScale(Y[i]));
        const svgLine = svg
          .append("path")
          .attr("fill", "none")
          .attr("class", `line line${i + 1}`)
          .attr("stroke", color)
          .attr("stroke-width", width)
          .attr("d", pathLine(I));
        const svgLineTotalLength = svgLine.node().getTotalLength();
        this.svgLineTotalArr[i] = svgLineTotalLength;
        svgLine
          .attr("stroke-dasharray", svgLineTotalLength + "," + svgLineTotalLength)
          .attr("stroke-dashoffset", svgLineTotalLength)
          .transition()
          .duration(duration)
          .ease(d3[ease])
          .attr("stroke-dashoffset", 0);
      }

      $("#" + this.id).html(svg.node());
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="less" scoped></style>
